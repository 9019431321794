import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e01eaaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_widget = _resolveComponent("header-widget")!
  const _component_token_form = _resolveComponent("token-form")!
  const _component_menu_widget = _resolveComponent("menu-widget")!
  const _component_publier_form = _resolveComponent("publier-form")!
  const _component_schema_form = _resolveComponent("schema-form")!
  const _component_importer_form = _resolveComponent("importer-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_widget),
    (!_ctx.profile.first_name)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_token_form)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.menuOption)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createElementVNode("span", {
                  class: "ariane",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetMenu()))
                }, "Retour à l'accueil")
              ]))
            : _createCommentVNode("", true),
          (!_ctx.menuOption)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                _createTextVNode(" Bonjour "),
                _createElementVNode("b", null, _toDisplayString(_ctx.profile.first_name) + " " + _toDisplayString(_ctx.profile.last_name), 1),
                _createTextVNode(", dites-nous ce qui vous amène. "),
                _createVNode(_component_menu_widget)
              ]))
            : _createCommentVNode("", true),
          (_ctx.menuOption === 'publier')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_publier_form)
              ]))
            : _createCommentVNode("", true),
          (_ctx.menuOption === 'schema')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_schema_form)
              ]))
            : _createCommentVNode("", true),
          (_ctx.menuOption === 'importer')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_importer_form)
              ]))
            : _createCommentVNode("", true)
        ]))
  ], 64))
}