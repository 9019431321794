import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["id"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DsfrBadge = _resolveComponent("DsfrBadge")!

  return (_ctx.errors.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", { id: _ctx.containerId }, [
          (_ctx.errors.length === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Aucune erreur"))
            : (_openBlock(), _createElementBlock("dl", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: error.type
                  }, [
                    _createElementVNode("dt", null, [
                      _createVNode(_component_DsfrBadge, {
                        type: "warning",
                        label: 'fieldName' in error ? error.fieldName : error.type,
                        noIcon: ""
                      }, null, 8, ["label"])
                    ]),
                    _createElementVNode("dd", null, _toDisplayString(error.message), 1)
                  ], 64))
                }), 128))
              ]))
        ], 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}